<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_SHIPPING_INFORMATION')" type="back" :settings="false" />
    </template>

    <div class="settingsinput-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.FirstName"
            name="FirstName"
            :required="validationRules?.FirstName?.required"
            :minlength="validationRules?.FirstName?.minimumLength"
            :maxlength="validationRules?.FirstName?.maximumLength"
            label="Primary Address"
            floating-label
            outline
            type="text"
            placeholder="Type here"
            info="This field is required."
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            label="Secondary Address"
            floating-label
            outline
            type="text"
            placeholder="Type here"
            info="This field is required."
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            label="District"
            floating-label
            outline
            type="text"
            placeholder="Type here"
            info="This field is required."
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            label="City"
            floating-label
            outline
            type="text"
            placeholder="Type here"
            info="This field is required."
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            label="Province"
            floating-label
            outline
            type="text"
            placeholder="Type here"
            info="This field is required."
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            label="Country"
            floating-label
            outline
            type="text"
            placeholder="Type here"
            info="This field is required."
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            label="Zip/Postal Code"
            floating-label
            outline
            type="text"
            placeholder="Type here"
            info="This field is required."
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-item>
            <f7-button fill large preloader> Update </f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, reactive, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "SettingsShippingPage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const formData = reactive({
      FirstName: "",
      LastName: "",
      Password: "",
      BirthDate: "",
      Gender: "",
    });

    const store = useStore();

    return { formData };
  },
});
</script>
